import { ErpTableOptions } from '@/types/type';

import { defineComponent, ref, toRaw } from 'vue';
export default (Instance: any) => {
  const tableRef = ref();

  const columnList: ErpTableOptions[] = [
    {
      label: '销售合同号',
      prop: 'businessContractNo',
      minWidth: 160,
      value: '',
      type: 'input',
    },
    {
      label: '客户',
      prop: 'customerName',
      minWidth: 160,
      value: '',
      type: 'input',
    },
    {
      label: '国家',
      prop: 'country',
      propDesc: 'countryDisplay',
      minWidth: 130,
      value: '',
      type: 'input',
    },
    {
      label: '品名',
      prop: 'nameCn',
      propDesc: 'nameCnMerged',
      minWidth: 160,
      value: '',
      type: 'input',
    },
    {
      label: '订单状态',
      prop: 'status',
      propDesc: 'statusDesc',
      minWidth: 130,
      value: [1, 4],
      type: 'multiSelect',
      options: 'order_status',
    },
    {
      label: '录入的应收项目',
      prop: 'arAmountManual',
      propDesc: 'arAmountManualDisplay',
      minWidth: 180,
      value: [],
      type: 'number',
      filterProp: ['arAmountManualMinVal', 'arAmountManualMaxVal'],
    },
    {
      label: '合同应收项目',
      prop: 'arAmountTotal',
      propDesc: 'arAmountTotalDisplay',
      minWidth: 160,
      value: [],
      type: 'number',
      filterProp: ['arAmountTotalMinVal', 'arAmountTotalMaxVal'],
    },
    {
      label: '修改后的应收项目',
      prop: 'arAmountTotalAfterModify',
      propDesc: 'arAmountTotalAfterModifyDisplay',
      minWidth: 190,
      value: [],
      type: 'number',
      filterProp: ['arAmountTotalAfterModifyMinVal', 'arAmountTotalAfterModifyMaxVal'],
    },

    {
      label: '供应商合同号',
      prop: 'supplierContractNo',
      minWidth: 170,
      value: '',
      type: 'input',
    },
    {
      label: '实际净重(kg)',
      prop: 'actualWeightSum',
      propDesc: 'actualWeightSumDisplay',
      minWidth: 160,
      value: [],
      type: 'number',
      filterProp: ['actualWeightMinVal', 'actualWeightMaxVal'],
    },
    {
      label: '待审核记录',
      prop: 'needAuditCount',
      minWidth: 160,
      value: [],
      type: 'number',
      filterProp: ['needAuditCountMinVal', 'needAuditCountMaxVal'],
    },
    {
      label: '订单创建时间',
      prop: 'createdTime',
      minWidth: 160,
      value: [],
      type: 'time',
      filterProp: ['createdTimeStartDate', 'createdTimeEndDate'],
    },
    {
      label: '操作',
      prop: '',
      fixed: 'right',
      width: 88,
      isFilter: false,
      type: 'input',
      value: '',
      className: 'erp-btn-groups',
      component: defineComponent({
        template: `
        <el-button  type="primary" plain  @click='addContract(row)' v-if="row.needAuditCount>0&&hasPermission('erp:fmArap:auditArap')" >审核</el-button>
        `,
        props: ['row'],
        setup() {
          const addContract = (row) => {
            Instance.setupState.auditForm(toRaw(row));
            return '';
          };
          return { addContract };
        },
      }),
    },
  ];
  return { columnList, tableRef };
};
